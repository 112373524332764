import { Container, Row, Col } from "react-bootstrap";
import bottomImg from "../assets/img/service-bottom.png";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import "animate.css";
import service from "../assets/img/services.png";
import Card from "react-bootstrap/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SiDeezer } from "react-icons/si";

export const ServiceBg = () => {
  return (
    <section className="serviceBg" id="home">
      <Container>
        <img className="service" src={service} alt="service" />
        <Row className="aligh-items-center">
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>MUSIC PROMOTION</h2>
            <ul class="a">
              <li style={{ paddingBottom: "20px" }}>
                {" "}
                Get chance to promote your music in our channel system
              </li>
              <li style={{ paddingBottom: "20px" }}>
                {" "}
                Reach your Music to 150+ Countries, millions of people in over
                the World
              </li>
              <li style={{ paddingBottom: "20px" }}>
                {" "}
                Increase your fan base with a suite of promotional and marketing
                services.
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>DIGITAL DISTRIBUTION</h2>
            <ul class="a">
              <li style={{ paddingBottom: "20px" }}>
                {" "}
                Steam and sell your music on over 130 digital stores like
                Spotify, iTunes, Deezer, Beatport and many others.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                {" "}
                Gain access to our dashboard for managing your music Catalogs.
              </li>
              <li>
                Upload your music with quick access and an easy to use release
                creation system.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="aligh-items-center">
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>CONTENT ID</h2>
            <ul class="a">
              <li>
                {" "}
                Musicians, Labels and Right Holders are able to automatically
                claim, manage, protect and monetize their content uploaded by
                third parties on YouTube.
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>SUPPORT & ADVICE</h2>
            <ul class="a">
              <li>
                We're here to provide support and professional advice to help
                you get the most from promoting and selling your music online.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="aligh-items-center">
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>TAKE CONTROL</h2>
            <ul class="a">
              <li style={{ paddingRight: "5px" }}>
                {" "}
                Use our online system to manage your catalogue, distribute your
                music and view your latest sales.
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <h2 style={{ color: "black" }}>MONTHLY PAYOUT</h2>
            <ul class="a">
              <li style={{ paddingBottom: "20px" }}>
                You receive 90% of the revenue we collect, we only charge 10%
                royalties.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                No upfront or hidden costs
              </li>
              <li>
                Get paid every month without any delays from PayPal or Bank
                Transfer!
              </li>
            </ul>
          </Col>
        </Row>
        
      </Container>
      <h2 className="aligh-center" style={{ paddingTop: "2em" }}>
          Distribute to the World’s Largest Music Platforms
        </h2>

        <h4 className="aligh-center-s" id="cuscard">
          {" "}
          The Soul of Wind is dedicated to delivering the best stores to our
          customers worldwide with a variety of partners including the world’s
          most popular dedicated download, streaming and video services. We
          currently partner with over 50 of the biggest digital stores.
        </h4>
      <img src={bottomImg} alt="service Img" />
    </section>
  );
};
