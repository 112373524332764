import meter1 from "../assets/img/thumbt1.jpg";
import meter2 from "../assets/img/thumb1.jpg";
import meter3 from "../assets/img/thumb2.jpg";
import meter4 from "../assets/img/thumb3.jpg";
import meter5 from "../assets/img/thumb4.jpg";
import meter6 from "../assets/img/thumb5.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              {/* <h2>Skills</h2> */}
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                dotListClass="custom-dot-list-style"
                partialVisible={false}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  
                  <a
                    className="a-artist"
                    href="https://youtu.be/hj83cwfOF3Y?si=pIq9HtSMye3nxp5J"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter3} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a
                    className="a-artist"
                    href="https://youtu.be/HSOtku1j600?si=iD9UY5tkOQ2CucV7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter2} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  
                  <a
                    className="a-artist"
                    href="https://youtu.be/bP9gMpl1gyQ?si=lMywbJ4dW-bNdKEI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter1} alt="Image" />
                  </a>
                </div>
                
                <div className="item">
                  <a
                    className="a-artist"
                    href="https://youtu.be/MYTfTKGSRr8?si=D52UZ5aVzZNtqE7p"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter4} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a
                    className="a-artist"
                    href="https://youtu.be/l7DVd3nwdaw?si=BNj7ajGKM2ypX4Q2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter5} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a
                    className="a-artist"
                    href="https://youtu.be/EBSegrHpreY?si=tEiuMzhhTa1Ke9wO"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter6} alt="Image" />
                  </a>
                </div>
             
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
