import { Col } from "react-bootstrap";
import navIcon1 from "../../assets/img/ytb.png";
import navIcon2 from "../../assets/img/spotify-logo.svg";
import navIcon3 from "../../assets/img/soundcloud-logo.svg";
export const AllReleaseCard = ({ title, description, imgUrl, linkUrlYtb, linkUrSty, linkUrScl }) => {
  return (
    <Col size={12} sm={5} md={3}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
         
          
            
            
          <div className="social-icon">
            
              <a href={linkUrSty} target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Icon" /></a>
             
            </div>
        </div>
        
      </div>
      
    </Col>
  )
}
