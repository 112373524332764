import meter1 from "../assets/img/five-1.svg";
import meter2 from "../assets/img/five-2.svg";
import meter3 from "../assets/img/five-3.svg";
import meter4 from "../assets/img/five-4.svg";
import meter5 from "../assets/img/five-5.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/sharp.png";

export const MidBanner = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  return (
    <section className="midbanner" id="skills">
      <div className="continer">
        <div className="">
          <div className="col-12">
            <div className="">
              <h1 style={{ marginLeft: "250px", marginTop: "100px" }}>
                Swept Away to Dreamland{" "}
              </h1>
              <h1 style={{ marginLeft: "450px" }}>on the Wings of Wind</h1>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <Carousel
                responsive={responsive}
                infinite={true}
                // autoPlay={true}
                // swipeable={true}
                // draggable={true}
                // showDots={true}
                arrows={false}
                // dotListClass="custom-dot-list-style"
                partialVisible={false}
                className="owl-carousel owl-theme"
              >
                <div className="item">
                  <a
                    href="https://open.spotify.com/user/5yzec7udna0kycze84uh0tgnj"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter1} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://music.amazon.com/artists/B08CRPN7S9/the-soul-of-wind" target="_blank"
                    rel="noopener noreferrer">
                    <img src={meter2} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.youtube.com/@TheSoulofWindLabel" target="_blank"
                    rel="noopener noreferrer">
                    <img src={meter3} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://music.apple.com/us/artist/the-soul-of-wind/1523029313" target="_blank"
                    rel="noopener noreferrer">
                    <img src={meter4} alt="Image" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://soundcloud.com/thesoulofwind" target="_blank"
                    rel="noopener noreferrer">
                    <img src={meter5} alt="Image" />
                  </a>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
