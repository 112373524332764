import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import video1 from "../assets/img/anh-vd1.jpg";
import video2 from "../assets/img/anh-vd2.jpg";
import video3 from "../assets/img/video2.jpg";

import "animate.css";

export const YoutubePlaylist = () => {
  const linkVideo1 =
    "https://www.youtube.com/embed/5AfDqIFAlB0?si=C6X7O9pEsvZ9kG0q&list=PLe533PvpOWlhd3DqxxiZuVFzLgyPTo35v";

  return (
    <section className="Artist" id="">
      <div>
        <Row>
          <Col size={12} sm={6}>
            <iframe
              width="100%"
              height="459"
              src={linkVideo1}
              title="YouTube video player"
              frameborder="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
          <Col size={12} sm={2} className="">
            <div className="videoYtb">
              <img className="imgYtb" src={video1} />
            </div>
            <div className="videoYtb">
              <img className="imgYtb" src={video2} />
            </div>
            <div className="videoYtb">
              <img className="imgYtb" src={video3} />
            </div>
          </Col>

          <Col size={12} sm={4} className="">
            <div className="textDes">
              <h5>
                STUDY WITH ME | A Rainy Day | Peaceful Piano Music for Studying
                and Sleeping
              </h5>
            </div>
            <div className="textDes">
              <h5>
                Healing Sleep Music - Peaceful Piano Music, Insomnia, Water
                Sounds | Relaxing & Meditation Music
              </h5>
            </div>
            <div className="textDes">
              <h5>
                Peaceful Piano Music, Anxiety and Depressive States, Mind
                Relaxing BGM, Fall Into Deep Sleep
              </h5>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};
