import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/guitar.svg";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "The soul of wind"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner">
      <Container>
        {/* <Row className="aligh-items-center"> */}
          {/* <Col xs={12} md={6} xl={7}> */}
            {/* <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> */}
                {/* <span className="tagline">Welcome to my Website</span> */}
                
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  <button onClick={() => console.log('connect')}>Youtube<ArrowRightCircle size={25} /></button> */}
              {/* </div>} */}
            {/* </TrackVisibility> */}
          {/* </Col> */}
          
        {/* </Row> */}
       
                  <div className="banner-nav">
                  <h1> <span className="txt-rotate" dataPeriod="1000" >The soul of Wind</span>
                  <div className="piano_png">
                  <a className="a-artist" href="https://www.youtube.com/@TheSoulofWindLabel" target="_blank" rel="noopener noreferrer">
                    <img src={headerImg}  alt="Header Img"/>
                    
                  </a>
                  
                  </div>
                  </h1>
                  </div>
                  
      
         
      </Container>
    </section>
  )
}
