import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/NavBar";
import { Banner } from "./components/Banner";
import Home from './components/Home';
import {Login} from './components/Login';
import  Services  from './components/Services';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Preloader from "../src/components/Pre";
import { SportifyPlaylist } from './components/SportifyPlaylist';
import { Contact } from './components/Contact';
import { Playlist1 } from './components/Playlist1';
import { Distribution } from './components/Distribution';
import { PianoMusic } from './components/Piano-music/Piano-music';
import { AllArtist } from './components/Artist/AllArtist';
import { AllRelease } from './components/AllRelease/AllRelease';
// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Carousel } from './components/Carousel1';
// import { slides } from "./data/carouselData.json";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <Preloader load={load} />
      
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/playlist" element={<Playlist1 />} />
          <Route path="/allartist" element={<AllArtist />} />
          <Route path="/allrelease" element={<AllRelease />} />
          <Route path="/about-us" element={<Distribution />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/piano-music" element={<PianoMusic />} />
          
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
