import "react-multi-carousel/lib/styles.css";
import pianomusic from "../assets/img/pl1.jpg";
import relaxingmusic from "../assets/img/pl2.png";
import rainmusic from "../assets/img/pl3.png";
import sleepmusic from "../assets/img/pl4.png";
import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
export const Playlist1 = () => {
  const navigate = useNavigate();
  const [expand, updateExpanded] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
    } else {
    }
  }

  window.addEventListener("scroll", scrollHandler);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  return (
    <section className="playlist1" id="skills">
      <div className="container bottpm">
        <div className="row">
          <div className="col-12">
            <div className="">
              <h1 style={{ marginLeft: "250px", marginTop: "100px" }}>
                Swept Away to Dreamland{" "}
              </h1>
              <h1 style={{ marginLeft: "450px" }}>on the Wings of Wind</h1>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <a
                className="a-artist"
                as={Link}
                to="/piano-music"
                onClick={() => navigate("/piano-music")}
              >
                <img src={pianomusic} alt="Image" />
              </a>
              <a
                className="a-artist"
                as={Link}
                to="/piano-music"
                onClick={() => navigate("/piano-music")}
              >
                <img src={relaxingmusic} alt="Image" />
              </a>
              <a
                className="a-artist"
                as={Link}
                to="/piano-music"
                onClick={() => navigate("/piano-music")}
              >
                <img src={rainmusic} alt="Image" />
              </a>
              <a
                className="a-artist"
                as={Link}
                to="/piano-music"
                onClick={() => navigate("/piano-music")}
              >
                <img src={sleepmusic} alt="Image" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};
