import { Row, Col, Tab, Nav, Container } from "react-bootstrap";
import Img from "../../assets/img/artist.png";
import projImg1 from "../../assets/img/all1.jpg";
import projImg2 from "../../assets/img/album2.jpg";
import projImg3 from "../../assets/img/album3.jpg";
import projImg4 from "../../assets/img/album4.jpg";
import projImg5 from "../../assets/img/album5.jpg";
import projImg6 from "../../assets/img/album6.jpg";
import projImg7 from "../../assets/img/album7.jpg";
import projImg8 from "../../assets/img/album8.jpg";
import projImg9 from "../../assets/img/album9.jpg";
import projImg10 from "../../assets/img/album10.jpg";
import projImg11 from "../../assets/img/album11.jpg";
import projImg12 from "../../assets/img/album12.jpg";
import projImg13 from "../../assets/img/album13.jpg";
import projImg14 from "../../assets/img/album14.jpg";
import projImg15 from "../../assets/img/album15.jpg";
import projImg16 from "../../assets/img/album16.jpg";
import projImg17 from "../../assets/img/album17.jpg";
import projImg18 from "../../assets/img/album18.jpg";
import projImg19 from "../../assets/img/album19.jpg";
import projImg20 from "../../assets/img/album20.jpg";
import projImg21 from "../../assets/img/album21.jpg";
import projImg22 from "../../assets/img/album22.jpg";
import projImg23 from "../../assets/img/album23.jpg";
import projImg24 from "../../assets/img/album24.jpg";
import projImg25 from "../../assets/img/album25.jpg";
import projImg26 from "../../assets/img/album26.jpg";
import projImg27 from "../../assets/img/album27.jpg";
import projImg28 from "../../assets/img/album28.jpg";
import projImg29 from "../../assets/img/album29.jpg";
import projImg30 from "../../assets/img/album30.jpg";
import projImg31 from "../../assets/img/album31.jpg";
import projImg32 from "../../assets/img/album32.jpg";
import projImg33 from "../../assets/img/album35.jpg";
import projImg34 from "../../assets/img/album33.jpg";
import projImg35 from "../../assets/img/album34.jpg";
import projImg36 from "../../assets/img/album36.jpg";
import projImg37 from "../../assets/img/album37.jpg";
import projImg38 from "../../assets/img/album39.jpg";
import projImg39 from "../../assets/img/album38.jpg";
import projImg40 from "../../assets/img/album40.jpg";
import projImg41 from "../../assets/img/album41.jpg";
import projImg42 from "../../assets/img/album42.jpg";
import projImg43 from "../../assets/img/album43.jpg";
import projImg44 from "../../assets/img/album44.jpg";
import projImg45 from "../../assets/img/album45.jpg";
import projImg46 from "../../assets/img/album46.jpg";
import projImg47 from "../../assets/img/album47.jpg";
import projImg48 from "../../assets/img/album48.jpg";
import projImg49 from "../../assets/img/album49.jpg";
import projImg50 from "../../assets/img/album50.jpg";
import projImg51 from "../../assets/img/album51.jpg";
import projImg52 from "../../assets/img/album52.jpg";
import projImg53 from "../../assets/img/album53.jpg";
import projImg54 from "../../assets/img/album54.jpg";
import projImg55 from "../../assets/img/album56.jpg";
import projImg56 from "../../assets/img/album55.jpg";
import projImg57 from "../../assets/img/album57.jpg";
import projImg58 from "../../assets/img/album58.jpg";
import projImg59 from "../../assets/img/album59.jpg";
import projImg60 from "../../assets/img/album60.jpg";
import projImg61 from "../../assets/img/album61.jpg";
import projImg62 from "../../assets/img/album62.jpg";
import projImg63 from "../../assets/img/album63.jpg";
import { AllReleaseCard } from "./AllReleaseCard";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Footer } from "../Footer";

export const AllRelease = () => {
  const projects = [
    {
      title: "The soul of wind",

      imgUrl: projImg1,
      linkUrlYtb:
        "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/album/01Az2H7VcN6anIYF6pCSq4",
      linkUrScl: "https://soundcloud.com/thesoulofwind",
    },
    {
      title: "A Sorrowful Rain",

      imgUrl: projImg2,
      linkUrSty: "https://open.spotify.com/album/610ahGn0THWbFeLnnoHwLT",
    },
    {
      title: "Gentle Wind at Night",
      linkUrSty: "https://open.spotify.com/album/2gL9KFo766CD0VjKykeVJ1",
      imgUrl: projImg3,
    },
    {
      title: "In The Bleak Midwinter",
      linkUrSty: "https://open.spotify.com/album/43G1rYsBkhFXqN395fRLXq",
      imgUrl: projImg4,
    },
    {
      title: "Story In The Rain",
      linkUrSty: "https://open.spotify.com/artist/06hlt7q1K1aJmUk5N8B5Re",
      imgUrl: projImg5,
    },
    {
      title: "The Sound of Time",
      linkUrSty: "https://open.spotify.com/album/3WvQjcnoup5bTdmS11GqYf",
      imgUrl: projImg6,
    },
    {
      title: "Wind's Soul",
      linkUrSty: "https://open.spotify.com/album/5hEfzi3Y8AHHNKu5GfedDe",
      imgUrl: projImg7,
    },
    {
      title: "Rhythm of the Rain",
      linkUrSty: "https://open.spotify.com/album/6hViyUHhIEjOc5FxZTxaBc",
      imgUrl: projImg8,
    },
    {
      title: "Relaxing Music: Spring",
      linkUrSty: "https://open.spotify.com/album/42Vr4Ju3vQlElOKFEqe0dt",
      imgUrl: projImg9,
    },
    {
      title: "Relaxing Music: Autumn",
      linkUrSty: "https://open.spotify.com/album/7fjrdE3O7SoMRZgE0EaIZz",
      imgUrl: projImg10,
    },
    {
      title: "Relaxing Music: Summer",
      linkUrSty: "https://open.spotify.com/album/0jOoA6s5mmzLfpa8x7R9yA",
      imgUrl: projImg11,
    },
    {
      title: "Peaceful Weekend",
      linkUrSty: "https://open.spotify.com/album/7cq0T4P5SKTvj7InwDAGB1",
      imgUrl: projImg12,
    },
    {
      title: "Sweet Time",
      linkUrSty: "https://open.spotify.com/album/0xEtjvs70nManhG3yQoanr",
      imgUrl: projImg13,
    },
    {
      title: "Summer Melodies",
      linkUrSty: "https://open.spotify.com/album/3mojfIGUNxD8JUWs8X23sM",
      imgUrl: projImg14,
    },
    {
      title: "Broken Heart",
      linkUrSty: "https://open.spotify.com/album/7xzrlfmp0Nftqgg0YRJ76k",
      imgUrl: projImg15,
    },
    {
      title: "Studio Ghibli Relaxing Piano Music Collection ~ Ghibli World",
      linkUrSty: "https://open.spotify.com/album/3TypTMZ88rWqHleIZIch1U",
      imgUrl: projImg16,
    },
    {
      title: "cozy christmas with you",
      linkUrSty: "https://open.spotify.com/album/2ZautBqaf4nDbkiAlijulV",
      imgUrl: projImg17,
    },
    {
      title: "Meteor in a Summer Night",
      linkUrSty: "https://open.spotify.com/album/3WsjqzX2EEWN4Ng82jcbLn",
      imgUrl: projImg18,
    },
    {
      title: "How To Spend Winter",
      linkUrSty: "https://open.spotify.com/album/7oSFyTnMj8eQWxaItPb91D",
      imgUrl: projImg19,
    },
    {
      title: "Peaceful City Night",
      linkUrSty: "https://open.spotify.com/album/3Tb1PjecLYegE9UypoYab3",
      imgUrl: projImg20,
    },
    {
      title: "Your Lie",
      linkUrSty: "https://open.spotify.com/album/7mRrhxSE8lKtZd1DN7KWz5",
      imgUrl: projImg21,
    },
    {
      title: "Fantasy Meteor sky",
      linkUrSty: "https://open.spotify.com/album/2OCoT9zrqJZudbXeRU34EG",
      imgUrl: projImg22,
    },
    {
      title: "Autumn Fairy Tale",
      linkUrSty: "https://open.spotify.com/album/1U63lGS2wCieiz3pAS6MHL",
      imgUrl: projImg23,
    },
    {
      title: "Lazy Sunday",
      linkUrSty: "https://open.spotify.com/album/1pKXIy4HbMC5tk8ygOt0aP",
      imgUrl: projImg24,
    },
    {
      title: "Relaxing Music: Winter",
      linkUrSty: "https://open.spotify.com/album/1Rc0wihGunJSPfrXKSKKVd",
      imgUrl: projImg25,
    },
    {
      title: "Restful Holidays",
      linkUrSty: "https://open.spotify.com/album/6XNXHYBugfpYqXxD4Y23fc",
      imgUrl: projImg26,
    },
    {
      title: "Blue Sky",
      linkUrSty: "https://open.spotify.com/album/4AP9OF9t2tgORwtLvCnJDV",
      imgUrl: projImg27,
    },
    {
      title: "Fairy Tale Library",
      linkUrSty: "https://open.spotify.com/album/4z6eq86VZq4LCcONtHghIM",
      imgUrl: projImg28,
    },
    {
      title: "Relaxing Vibes",
      linkUrSty: "https://open.spotify.com/album/1hMkjurzHoNKFOXfkl2am9",
      imgUrl: projImg29,
    },
    {
      title: "Wind Rain",
      linkUrSty: "https://open.spotify.com/album/4Es3JWRVSAosXA5HBGBPJb",
      imgUrl: projImg30,
    },
    {
      title: "Moonlight on The Sea",
      linkUrSty: "https://open.spotify.com/album/6bxc55Ohq8QiypR5K9nPrL",
      imgUrl: projImg31,
    },
    {
      title: "Sleeping Tranquility",
      linkUrSty: "https://open.spotify.com/album/0e12EWsHTErJEiO47Xoejv",
      imgUrl: projImg32,
    },
    {
      title: "Shower",
      linkUrSty: "https://open.spotify.com/album/31hqdhWPPD1QO9r9jLr5MY",
      imgUrl: projImg33,
    },
    {
      title: "Nostalgia winter",
      linkUrSty: "https://open.spotify.com/album/5fBIW0HOHwhXyPxQRWRKHS",
      imgUrl: projImg34,
    },
    {
      title: "Flowers and leaves Current",
      linkUrSty: "https://open.spotify.com/album/4NnLHgVfps8K1JPmm3dTI9",
      imgUrl: projImg35,
    },
    {
      title: "Clove",
      linkUrSty: "https://open.spotify.com/album/14xTrQHWAPuI8rLG9Jwt6q",
      imgUrl: projImg36,
    },
    {
      title: "At The End of Twilight",
      linkUrSty: "https://open.spotify.com/album/0rDInMqDyCozSTo2jtPQby",
      imgUrl: projImg37,
    },
    {
      title: "Sky Floating Clouds",
      linkUrSty: "https://open.spotify.com/album/2LnuA56qHGwmh2Ykb9oZtP",
      imgUrl: projImg38,
    },
    {
      title: "Energy For a New Day",
      linkUrSty: "https://open.spotify.com/album/7yBTshPwDPektfVUnACy7I",
      imgUrl: projImg39,
    },
    {
      title: "Christmas in My Heart",
      linkUrSty: "https://open.spotify.com/album/4otR3Zvw6Max8PY49w2o2B",
      imgUrl: projImg40,
    },
    {
      title: "Love sincerely",
      linkUrSty: "https://open.spotify.com/album/1ikwSaWrHk1j3VZ72NNPH6",
      imgUrl: projImg41,
    },
    {
      title: "Destiny",
      linkUrSty: "https://open.spotify.com/album/2UlgVagt7iNRtxW0Lqe8Y6",
      imgUrl: projImg42,
    },
    {
      title: "Love Someone",
      linkUrSty: "https://open.spotify.com/album/1YNr2sPBGHvX6Qr5c2Qhb1",
      imgUrl: projImg43,
    },
    {
      title: "White Night",
      linkUrSty: "https://open.spotify.com/album/1hoDOyyZQciq0P1YGaSAWC",
      imgUrl: projImg44,
    },
    {
      title: "Sakura Hanami",
      linkUrSty: "https://open.spotify.com/album/2wTlNI67XqUCImU4YnAi4x",
      imgUrl: projImg45,
    },
    {
      title: "A Tender Feeling",
      linkUrSty: "https://open.spotify.com/album/2Wh0yhnH1CJITZG8wOgxxr",
      imgUrl: projImg46,
    },
    {
      title: "Summer Starlight",
      linkUrSty: "https://open.spotify.com/album/0YiN5c1qug3s7KiTECmkBH",
      imgUrl: projImg47,
    },
    {
      title: "Your Forever Fall",
      linkUrSty: "https://open.spotify.com/album/02UJ6YREhTSxeoHPrYwr0Z",
      imgUrl: projImg48,
    },
    {
      title: "Stories in the Wind",
      linkUrSty: "https://open.spotify.com/album/0DSSFTaj5mtHP5OD4XpOq6",
      imgUrl: projImg49,
    },
    {
      title: "A Letter To The Rain",
      linkUrSty: "https://open.spotify.com/album/6FS276BBE52whlPxdQlkIs",
      imgUrl: projImg50,
    },
    {
      title: "After Sunset",
      linkUrSty: "https://open.spotify.com/album/2AqYNqKsa9YnCsfz6TsMsm",
      imgUrl: projImg51,
    },
    {
      title: "A Day in Rain",
      linkUrSty: "https://open.spotify.com/album/0SRQrG4XoVbu2TKWdvtWHh",
      imgUrl: projImg52,
    },
    {
      title: "The Soul of Wind (Piano and Rain)",
      linkUrSty: "https://open.spotify.com/album/6CnTF0ry11eeMnlxLszsa2",
      imgUrl: projImg53,
    },
    {
      title: "When The Rain Falls",
      linkUrSty: "https://open.spotify.com/album/2tL9qDzuEsfLe40ky1nxfq",
      imgUrl: projImg54,
    },
    {
      title: "Sunsets in Summer",
      linkUrSty: "https://open.spotify.com/album/2Pbg2YwoBD1P3uglmTu6OF",
      imgUrl: projImg55,
    },
    {
      title: "My Winter Heart",
      linkUrSty: "https://open.spotify.com/album/5E50gXl8pLhowAdAQnYNSc",
      imgUrl: projImg56,
    },
    {
      title: "Could it be just a dream?",
      linkUrSty: "https://open.spotify.com/album/6KZYtDLd1avQ9bfwsZSEAh",
      imgUrl: projImg57,
    },
    {
      title: "Could it be just a dream?",
      linkUrSty: "https://open.spotify.com/album/6KZYtDLd1avQ9bfwsZSEAh",
      imgUrl: projImg57,
    },
    {
      title: "Waiting for the Light",
      linkUrSty: "https://open.spotify.com/album/2PHQrrz8I95vQbZ5fI3oH8",
      imgUrl: projImg58,
    },
    {
      title: "Merry Christmas Eve (Peaceful Piano)",
      linkUrSty: "https://open.spotify.com/album/1EJ6v4rgeoEtKSrdtFk5uV",
      imgUrl: projImg59,
    },
    {
      title: "Silent Night, Peaceful Night, Oh Christmas",
      linkUrSty: "https://open.spotify.com/album/7k8bzSDZAh6uuhxGCiurMJ",
      imgUrl: projImg60,
    },
    {
      title: "Gentle Wind at Night",
      linkUrSty: "https://open.spotify.com/album/2gL9KFo766CD0VjKykeVJ1",
      imgUrl: projImg61,
    },
    {
      title: "Studio Ghibli Relaxing Piano Music Collection ~ Ghibli World",
      linkUrSty: "https://open.spotify.com/album/3TypTMZ88rWqHleIZIch1U",
      imgUrl: projImg62,
    },
    {
      title: "Cozy christmas with you",
      linkUrSty: "https://open.spotify.com/album/2ZautBqaf4nDbkiAlijulV",
      imgUrl: projImg63,
    },
  ];
  return (
    <section id="allartist">
      <Container>
        <Row>
          <Col size={12} sm={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <Row>
                    {projects.map((project, index) => {
                      return <AllReleaseCard key={index} {...project} />;
                    })}
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>{" "}
      </Container>

      <Footer />
    </section>
  );
};
