import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
// import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiItunes, SiTiktok,SiYoutubemusic, SiDeezer, SiBandcamp } from "react-icons/si";
import { BsSpotify, BsInstagram, BsYoutube, BsFacebook, BsTwitter } from "react-icons/bs";
import { AiOutlineAmazon } from "react-icons/ai";
import { PiSoundcloudLogoFill, PiTidalLogoFill } from "react-icons/pi";
import Button from "react-bootstrap/Button";
export const Footer = () => {
  const navigate = useNavigate();
  const [expand, updateExpanded] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
    } else {
    }
  }

  window.addEventListener("scroll", scrollHandler);
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={3}>
            <div as={Link} to="/about-us" onClick={() => navigate("/about-us")}>
              <span className="footer-text">About Us</span>
            </div>

            <div
              as={Link}
              to="/allartist"
              onClick={() => navigate("/allartist")}
            >
              <span className="footer-text">Artist</span>
            </div>
            <div as={Link} to="/services" onClick={() => navigate("/services")}>
              <span className="footer-text">Services</span>
            </div>
          </Col>
          <Col size={12} sm={4}>
            <div
              as={Link}
              to="/allrelease"
              onClick={() => navigate("/allrelease")}
            >
              <span className="footer-text">Album</span>
            </div>
            <div
              as={Link}
              to="/contactus"
              onClick={() => navigate("/contactus")}
            >
              <span className="footer-text">Contact </span>
            </div>
          </Col>
          <Col size={12} sm={5} className="">
            <div className="social-icon-footer">
              <a
                href="https://open.spotify.com/user/5yzec7udna0kycze84uh0tgnj"
                target="_blank"
                className="fork-btn-inner"
              >
                <BsSpotify style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.instagram.com/thesoulofwinds/"
                target="_blank"
                className="fork-btn-inner"
              >
                <BsInstagram style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.youtube.com/@TheSoulofWindLabel"
                target="_blank"
                className="fork-btn-inner"
              >
                <BsYoutube style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.tiktok.com/@thesoulofwindlabel"
                target="_blank"
                className="fork-btn-inner"
              >
                <SiTiktok style={{ fontSize: "2em" }} />
              </a>
             
              <a
                href="https://music.apple.com/us/artist/the-soul-of-wind/1523029313"
                target="_blank"
                className="fork-btn-inner"
              >
                <SiItunes style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.facebook.com/TheSoulOfWinds"
                target="_blank"
                className="fork-btn-inner"
              >
                <BsFacebook style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://twitter.com/TheSoulofWindx"
                target="_blank"
                className="fork-btn-inner"
              >
                <BsTwitter style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://music.amazon.com/artists/B08CRPN7S9/the-soul-of-wind"
                target="_blank"
                className="fork-btn-inner"
              >
                <AiOutlineAmazon style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://soundcloud.com/thesoulofwind"
                target="_blank"
                className="fork-btn-inner"
              >
                <PiSoundcloudLogoFill style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCXyHAsQPCp1Nq4Cz4Qq6KdQ"
                target="_blank"
                className="fork-btn-inner"
              >
                <SiYoutubemusic style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.youtube.com/@TheSoulofWindLabel"
                target="_blank"
                className="fork-btn-inner"
              >
                <PiTidalLogoFill style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.deezer.com/us/artist/100393692"
                target="_blank"
                className="fork-btn-inner"
              >
                <SiDeezer style={{ fontSize: "2em" }} />
              </a>
              <a
                href="https://www.youtube.com/@TheSoulofWindLabel"
                target="_blank"
                className="fork-btn-inner"
              >
                <SiBandcamp style={{ fontSize: "2em" }} />
              </a>
            </div>
            <hr className="hr"  width="90%" align="center" />
            <p>Copyright 2023 The Soul of wind. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
