import "react-multi-carousel/lib/styles.css";
import React, { useState } from "react";
import {SiDeezer } from "react-icons/si";
import "animate.css";
import { Footer } from "./Footer";
import { Container, Row } from "react-bootstrap";
import TrackVisibility from "react-on-screen";

export const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");

  const handleValidation = (event) => {
    let formIsValid = true;

    if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    if (!password.match(/^[a-zA-Z]{8,22}$/)) {
      formIsValid = false;
      setpasswordError(
        "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
      );
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation();
  };
  return (
    <section className="contact">
        <Container  className="login-form"> 
    
      <TrackVisibility>
        <div className="row justify-content-center cus-login">
          <div className="col-md-4">
            
            <form id="loginform" onSubmit={loginSubmit}>
            <h2 style={{ color: "#121212", marginBottom: "10px", marginTop: "10px" }}>Login</h2>
              <div className="form-group">
                <label style={{ color: "#121212", marginBottom: "10px", marginTop: "10px" }}>Username</label>
                <input
                  type="email"
                  className="form-control"
                  id="EmailInput"
                  name="EmailInput"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={(event) => setEmail(event.target.value)}
                />
                <small id="emailHelp" className="text-danger form-text">
                  {emailError}
                </small>
              </div>
              <div className="form-group">
                <label style={{ color: "#121212", marginBottom: "10px", marginTop: "10px" }}>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <small id="passworderror" className="text-danger form-text">
                  {passwordError}
                </small>
              </div>
              <div>
                
                <label style={{paddingTop: "15px"}}>Forgot your password ?</label>
              </div>
              <h6 style={{ color: "#007bff", marginBottom: "5px", marginTop: "5px" }}>Forgot your password?</h6>
              <button className="btn-contact" type="submit">
                <SiDeezer className="btn-submit" />

                <span>Submit</span>
              </button>
            </form>
          </div>
        </div>
        </TrackVisibility>
     
        </Container>
      
      <Footer />
    </section>
  );
};
