import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { SiDeezer } from "react-icons/si";
import { Footer } from "./Footer";

export const Contact = () => {
  const formInitialDetails = {
    fullName: "",
    company: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    catalogueSize: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send Now");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // setButtonText("Sending...");
    // let response = await fetch("http://localhost:5000/contact", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    //   },
    //   body: JSON.stringify(formDetails),
    // });
    // setButtonText("Send Now");
    // let result = await response.json();
    // setFormDetails(formInitialDetails);
    // if (result.code == 200) {
    //   setStatus({ succes: true, message: "Message sent successfully" });
    // } else {
    //   setStatus({
    //     succes: false,
    //     message: "Something went wrong, please try again later.",
    //   });
    // }
  };

  return (
    <section className="contact" id="connect">
      <Container  style={{ marginBottom: "6em" }}>
        <div className="contact-padding">
          <TrackVisibility>
            {({ isVisible }) => (
              <div
                className={isVisible ? "animate__animated animate__fadeIn" : ""}
              >
                <h2 style={{ color: "white" }}>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                  <Row
                    style={{ border: "1px solid #e9e7e7", padding: "3em 3em" }}
                  >
                    <h5 style={{ paddingBottom: "20px", color: "#888888" }}>
                      Fill in this form to get more information. We’ll contact
                      you within 24 hours.
                    </h5>

                    <input
                      type="text"
                      value={formDetails.fullName}
                      placeholder="Full name"
                      onChange={(e) => onFormUpdate("fullName", e.target.value)}
                    />

                    <input
                      type="text"
                      value={formDetails.company}
                      placeholder="Company"
                      onChange={(e) => onFormUpdate("company", e.target.value)}
                    />

                    <input
                      type="email"
                      value={formDetails.email}
                      placeholder="Email Address"
                      onChange={(e) => onFormUpdate("email", e.target.value)}
                    />

                    <input
                      type="tel"
                      value={formDetails.phone}
                      placeholder="Phone No."
                      onChange={(e) => onFormUpdate("phone", e.target.value)}
                    />

                    <input
                      type="tel"
                      value={formDetails.country}
                      placeholder="Country"
                      onChange={(e) => onFormUpdate("country", e.target.value)}
                    />

                    <input
                      type="tel"
                      value={formDetails.city}
                      placeholder="City"
                      onChange={(e) => onFormUpdate("city", e.target.value)}
                    />

                    <input
                      type="tel"
                      value={formDetails.catalogueSize}
                      placeholder="Catalogue Size"
                      onChange={(e) =>
                        onFormUpdate("catalogueSize", e.target.value)
                      }
                    />

                    <textarea
                      rows="6"
                      value={formDetails.message}
                      placeholder="Describle your catalog briefy"
                      onChange={(e) => onFormUpdate("message", e.target.value)}
                    ></textarea>

                    {/* <button className="btn-contact" type="submit"><span>{buttonText}</span></button> */}

                    {status.message && (
                      <Col>
                        <p
                          className={
                            status.success === false ? "danger" : "success"
                          }
                        >
                          {status.message}
                        </p>
                      </Col>
                    )}
                    <div className="cus-contact">
                      <button className="btn-contact" type="submit">
                        <SiDeezer className="btn-submit" />

                        <span>Send Now</span>
                      </button>
                    </div>
                  </Row>
                </form>
              </div>
            )}
          </TrackVisibility>
        </div>
      </Container>
      <Footer></Footer>
    </section>
  );
};
