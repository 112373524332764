import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Img from "../../assets/img/artist.png";
import projImg1 from "../../assets/img/the-soul-of-wind.jpg";
import projImg2 from "../../assets/img/big-rice-piano.jpg";
import projImg3 from "../../assets/img/annie-moon.jpg";
import projImg4 from "../../assets/img/jessica-yui.jpg";
import { ArtistCard } from "./ArtistCard";
import "animate.css";
import TrackVisibility from "react-on-screen";


export const Artist = () => {
  const projects = [
    {
      title: "The soul of wind",

      imgUrl: projImg1,
      linkUrlYtb:
        "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty:
        "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind",
    },
    {
      title: "Big Rice Piano",

      imgUrl: projImg2,
      linkUrSty:
        "https://open.spotify.com/artist/6NZehyzoXBTOmvFzJyp6RV?si=p7Gw7CntRTGfV8UiNq-_Swd",
    },
    {
      title: "Annie Moon",
      linkUrSty:
        "https://open.spotify.com/artist/0DBGOlOhSrYC8CGVzDxCZv?si=RvrqacG4T4GqEFwvWCo1qw",
      imgUrl: projImg3,
    },
    {
      title: "Jessica Yui",
      linkUrSty:
        "https://open.spotify.com/artist/3CIAlgukCQNEqhcDQ4pHX6?si=IuYO4ZrET0uvQoFmKEDIgw",
      imgUrl: projImg4,
    },
  ];
  return (
    <section className="Artist" id="">
      <img src={Img} alt="Img" />

      <Row>
        <Col size={12} sm={12}>
          <TrackVisibility>
            {({ isVisible }) => (
              <div
                className={isVisible ? "animate__animated animate__fadeIn" : ""}
              >
                <Row>
                  {projects.map((project, index) => {
                    return <ArtistCard key={index} {...project} />;
                  })}
                </Row>
              </div>
            )}
          </TrackVisibility>
        </Col>
      </Row>
      <div>
      </div>
    </section>
  );
};
