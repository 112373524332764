
import 'animate.css';
// import serviceImg from "../assets/img/services.png";
import { ServiceBg } from "./ServiceBg";
import { Footer } from "./Footer";

function Services() {
 
  return (
    <section className="service" id="home">
    
     
      < ServiceBg />
      <Footer />
    </section>
  )
}
export default Services;
