import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

// import logo from '../assets/img/logo1.svg';
// import {
//   AiOutlineHome,
//   AiOutlineUser,
// } from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);


  function scrollHandler() {
    if (window.scrollY >= 20) {

    } else {

    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar expanded={expand} fixed="top" expand="md" className="sticky">
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <span className="navbar-logo">The Soul of wind</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>

            <NavDropdown title="Distribution" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link}
                to="/allartist"
                onClick={() => updateExpanded(false)}>Artist</NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item as={Link}
                to="/allrelease"
                onClick={() => updateExpanded(false)}>
                Album
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/services"
                onClick={() => updateExpanded(false)}
              >
                Services
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about-us"
                onClick={() => updateExpanded(false)}
              >
                About Us
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contactus"
                onClick={() => updateExpanded(false)}
              >
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/login"
                onClick={() => updateExpanded(false)}
              >
                Login
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
