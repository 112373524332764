import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Footer } from "../Footer";
import projImg1 from "../../assets/img/the-soul-of-wind.jpg";
import projImg2 from "../../assets/img/Playlist/Deep Sleeping Music.jpg";
import projImg3 from "../../assets/img//Playlist/Healing.jpg";
import projImg4 from "../../assets/img/Playlist/Beautiful Piano for Sleep,Relax.png";
import projImg5 from "../../assets/img/Playlist/Piano Music _ Rain Sounds 🌧.jpg";
import projImg6 from "../../assets/img/Playlist/Relaxing Music + Rain Sounds.jpg";
import projImg7 from "../../assets/img/Playlist/Relaxing Music for Sleep, Study.jpg";
import projImg8 from "../../assets/img/Playlist/Sakura Piano Melodies.png";
import projImg9 from "../../assets/img/Playlist/Sleep,Study,Work.jpg";

import colorSharp2 from "../../assets/img/sharp.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { PianoMusicCard } from "./Piano-musicCard";

export const PianoMusic = () => {

  const projects = [
    {
    
      imgUrl: projImg1,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg2,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg3,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg4,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg5,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg6,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg7,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg8,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    },
    {
      imgUrl: projImg9,
      linkUrlYtb: "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X?si=sBBQQi1wSg2vgu2X6T0-yA&nd=1h",
      linkUrScl: "https://soundcloud.com/thesoulofwind"
    }
  ];

  return (
    <section className="pianomusic" id="project">
     <div className="piano-centure">
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                {/* <h2>Projects</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
               
                  {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab"> */}
                    {/* <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      {/* <Nav.Link eventKey="third" className="tabline">Distribution</Nav.Link> */}
                    </Nav.Item>
                  {/* </Nav> */}
            
              
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <PianoMusicCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    
                
              
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
        </div>
        <Footer />
    
     
      
    </section>
  )
}
