import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import { Footer } from "./Footer";
import Card from "react-bootstrap/Card";
import Carousel from "react-multi-carousel";
import meter1 from "../assets/img/shazam.png";
import meter2 from "../assets/img/spoti.png";
import meter3 from "../assets/img/soundcl.png";
import meter4 from "../assets/img/itunes.png";
import meter5 from "../assets/img/youtube.png";
import meter6 from "../assets/img/googleplay.png";
export const Distribution = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <section className="serviceBg" id="home">
      <Container>
        <Row className="aligh-items-center">
          {/* <Col xs={12} md={6} xl={7}> */}
          {/* <img className="distribution" src={distribution} alt="distribution" /> */}
          {/* </Col> */}
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/6No3BWbVU3Q?si=eT-AjMML7a-X3gWa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
        </Row>

        {/* <img src={headerImg} alt="Header Img"/> */}

        <h2 className="aligh-center" style={{ paddingTop: "2em" }}>
          WE ARE THE SOUL OF WIND
        </h2>
        <h3 className="aligh-center-2">
          A digital music distributor with global reach
        </h3>
        <h4 className="aligh-center-s" id="cuscard">
          {" "}
          With over seven years of experience in the music industry, our team
          have been working as skilled label managers, producers, artists, and
          performers, has been instrumental in advancing the careers of numerous
          artists, musicians, and YouTube creators.
        </h4>
        <h4 className="aligh-center-s" id="cuscard">
          {" "}
          We bring a wealth of knowledge in music distribution, coupled with
          over nine years of expertise in YouTube content creation, to enhance
          your visibility and boost your revenue in the music and media content
          landscape.
        </h4>
        <h1 className="aligh-center" style={{ paddingTop: "1em" }}>
          Why Choose The Soul of Wind?
        </h1>
        <Row style={{ paddingTop: "4em" }}>
          <Col xs={12} md={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="cardgroup">
                  <h4>Worldwide Music Distribution</h4>
                </Card.Title>
                <Card.Text className="cardgroup" id="cuscard">
                  <p>
                    {" "}
                    Get your music playing in over 130 digital stores and
                    streaming services across 150+ countries worldwide.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="cardgroup">
                  <h4>Diversity</h4>
                </Card.Title>
                <Card.Text className="cardgroup">
                  <p id="cuscard">
                    {" "}
                    We collaborate with Artists, Labels and Content Creators. We
                    have engineers and systems ready to support and promote your
                    products, helping partners grow and break through.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="cardgroup">
                  <h4>Copyright Protection</h4>
                </Card.Title>
                <Card.Text className="cardgroup">
                  <p id="cuscard">
                    {" "}
                    We make sure to increase your views and revenues, managing
                    and protecting your rights on YouTube, Facebook and some
                    other platforms with a bunch of powerful tools.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={3}>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title className="cardgroup">
                  <h4>Comprehensive Sales Data</h4>
                </Card.Title>
                <Card.Text className="cardgroup">
                  <p id="cuscard">
                    {" "}
                    Find out exactly where fans are downloading and streaming
                    your music so you can increase marketing efforts and plan
                    tours around those cities.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          dotListClass="custom-dot-list-style"
          partialVisible={false}
          className="owl-carousel owl-theme service-slider"
        >
          <div className="item">
            <a
              className="a-artist"
              href="https://soundcloud.com/thesoulofwind"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter3} alt="Image" />
            </a>
          </div>
          <div className="item">
            <a
              className="a-artist"
              href="https://open.spotify.com/user/5yzec7udna0kycze84uh0tgnj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter2} alt="Image" />
            </a>
          </div>
          <div className="item">
            <a
              className="a-artist"
              href="https://www.youtube.com/@TheSoulofWindLabel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter1} alt="Image" />
            </a>
          </div>

          <div className="item">
            <a
              className="a-artist"
              href="https://music.apple.com/us/artist/the-soul-of-wind/1523029313"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter4} alt="Image" />
            </a>
          </div>
          <div className="item">
            <a
              className="a-artist"
              href="https://www.youtube.com/@TheSoulofWindLabel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter5} alt="Image" />
            </a>
          </div>
          <div className="item">
            <a
              className="a-artist"
              href="https://www.youtube.com/@TheSoulofWindLabel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={meter6} alt="Image" />
            </a>
          </div>
        </Carousel>
      </Container>
      <Footer />
    </section>
  );
};
