import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Img from "../../assets/img/artist.png";
import projImg1 from "../../assets/img/the-soul-of-wind.jpg";
import projImg2 from "../../assets/img/big-rice-piano.jpg";
import projImg3 from "../../assets/img/annie-moon.jpg";
import projImg4 from "../../assets/img/jessica-yui.jpg";
import projImg5 from "../../assets/img/dj-andy.jpg";
import projImg6 from "../../assets/img/Justin-Jet-Zorbas.jpg";
import projImg7 from "../../assets/img/azusoul.jpg";
import projImg8 from "../../assets/img/Tsuyun.jpg";
import projImg9 from "../../assets/img/like-gentle-rain.jpg";
import projImg10 from "../../assets/img/healing.jpg";
import projImg11 from "../../assets/img/Danel-Skymoon.jpg";
import projImg12 from "../../assets/img/Vindhie-Lin.jpg";
import projImg12A from "../../assets/img/olly-wendy.jpg";
import projImg14 from "../../assets/img/eleftherios.jpg";
import projImg15 from "../../assets/img/micheal-winds.jpg";
import projImg16 from "../../assets/img/Elysian Composer.jpg";
import projImg17 from "../../assets/img/Love Encounters.jpg";
import projImg18 from "../../assets/img/Michael Azusa.jpg";
import projImg19 from "../../assets/img/Jessica Wen Jun Ciprian.jpg";
import { ArtistCard } from "./ArtistCard";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Footer } from "../Footer";

export const AllArtist = () => {
  const projects = [
    {
      title: "The soul of wind",

      imgUrl: projImg1,
      linkUrlYtb:
        "https://www.youtube.com/live/mefaPYAE6e8?si=2GhNr4Xlfa2RWgyh",
      linkUrSty: "https://open.spotify.com/artist/7r0YQP2my9HQPBOx1Gnn6X",
      linkUrScl: "https://soundcloud.com/thesoulofwind",
    },
    {
      title: "Big Rice Piano",

      imgUrl: projImg2,
      linkUrSty:
        "https://open.spotify.com/artist/6NZehyzoXBTOmvFzJyp6RV?si=p7Gw7CntRTGfV8UiNq-_Swd",
    },
    {
      title: "Annie Moon",
      linkUrSty:
        "https://open.spotify.com/artist/0DBGOlOhSrYC8CGVzDxCZv?si=RvrqacG4T4GqEFwvWCo1qw",
      imgUrl: projImg3,
    },
    {
      title: "Jessica Yui",
      linkUrSty:
        "https://open.spotify.com/artist/3CIAlgukCQNEqhcDQ4pHX6?si=IuYO4ZrET0uvQoFmKEDIgw",
      imgUrl: projImg4,
    },
    {
      title: "DJ Andy",
      linkUrSty: "https://open.spotify.com/artist/06hlt7q1K1aJmUk5N8B5Re",
      imgUrl: projImg5,
    },
    {
      title: "Justin Jet Zorbas",
      linkUrSty: "https://open.spotify.com/artist/10G9N5ABjyamTFBEAsquNs",
      imgUrl: projImg6,
    },
    {
      title: "Azusoul",
      linkUrSty: "https://open.spotify.com/artist/0BWejg7fkqmjSkFLjoR90W",
      imgUrl: projImg7,
    },
    {
      title: "Tsuyun",
      linkUrSty: "https://open.spotify.com/artist/3A7Mn8YlwLnpUIvIeHdGXn",
      imgUrl: projImg8,
    },
    {
      title: "Like gentle rain",
      linkUrSty: "https://open.spotify.com/artist/1buksjoq8qDrWEYexFNnG4",
      imgUrl: projImg9,
    },
    {
      title: "a Healing",
      linkUrSty: "https://open.spotify.com/artist/1ky9AutfgEv5IsgVnFgA6K",
      imgUrl: projImg10,
    },
    {
      title: "Danel Skymoon",
      linkUrSty: "https://open.spotify.com/artist/3g9ES1Lykj4qBxdIKyUx1j",
      imgUrl: projImg11,
    },
    {
      title: "Vindhie-Lin",
      linkUrSty: "https://open.spotify.com/artist/1LUgPWVszZObotEELaG6qr",
      imgUrl: projImg12,
    },
    {
      title: "Olly Wendy",
      linkUrSty: "https://open.spotify.com/artist/4xFqfvcHlUsEi5oJeO4MOc",
      imgUrl: projImg12A,
    },
    {
      title: "Eleftherios",
      linkUrSty: "https://open.spotify.com/artist/6z7DDiVB1Cca0P1VcsvxM9",
      imgUrl: projImg14,
    },
    {
      title: "Micheal Winds",
      linkUrSty: "https://open.spotify.com/artist/5WNv3PnnAieLnQ01nESJsW",
      imgUrl: projImg15,
    },
    {
      title: "Elysian Composer",
      linkUrSty: "https://open.spotify.com/artist/393wb6uLEZ4yGJNGX0KjLc",
      imgUrl: projImg16,
    },
    {
      title: "PianoShaman",
      linkUrSty: "https://open.spotify.com/artist/3nVBA52St39oz3t6yLu3ot",
      imgUrl: projImg17,
    },
    {
      title: "Michael Azusa",
      linkUrSty: "https://open.spotify.com/artist/4DakLJ5xCT2AFqfLzJksMW",
      imgUrl: projImg18,
    },
    {
      title: "Jessica Wen Jun Ciprian",
      linkUrSty: "https://open.spotify.com/artist/2vQOTzHVXCnNCrkBmc3hIb",
      imgUrl: projImg19,
    },
  ];
  return (
    <section id="allartist">
      <Container>
        <Row>
          <Col size={12} sm={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <Row>
                    {projects.map((project, index) => {
                      return <ArtistCard key={index} {...project} />;
                    })}
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};
