import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { MidBanner } from './MidBanner5';
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { SportifyPlaylist } from './SportifyPlaylist';
import { Artist } from '../components/Artist/Artist';
import Preloader from "./Pre";
import  { useState, useEffect } from "react";
import { YoutubePlaylist } from "./YoutubePlaylist";
// import homeLogo from "../../Assets/home-main.svg";
// import Particle from "../Particle";
// import Home2 from "./Home2";
// import Type from "./Type";

function Home() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    
    <section>
      <Preloader load={load} />
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            {/* <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> SUKRUT RAHANE </strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col> */}
        
            {/* <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col> */}
          </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
      <Banner />
      
      <Skills />
      <MidBanner />
      <SportifyPlaylist />
      <Artist />
      {/* <Contact /> */}
      <YoutubePlaylist />
      <Footer />
    </section>
  );
}

export default Home;