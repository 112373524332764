import meter1 from "../assets/img/Playlist/Beautiful Piano for Sleep,Relax.png";
import meter2 from "../assets/img/Playlist/Deep Sleeping Music.jpg";
import meter3 from "../assets/img/Playlist/Healing.jpg";
import meter4 from "../assets/img/Playlist/Piano Music _ Rain Sounds 🌧.jpg";
import meter5 from "../assets/img/Playlist/Relaxing Music for Sleep, Study.jpg";
import meter6 from "../assets/img/Playlist/Sakura Piano Melodies.png";
import meter7 from "../assets/img/Playlist/Sleep,Study,Work.jpg";
import meter8 from "../assets/img/Playlist/The Soul of Wind.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import Img from "../assets/img/sportify.png";
// import colorSharp from "../assets/img/sharp.png"

export const SportifyPlaylist = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  return (
    <section className="SportifyPlaylist" id="">
      <div className="">
        <div className="">
          <div className="col-12">
            <img src={Img} alt="Img" />
            <div className="">
              {/* <h2 id="spotify-title">Sportify Playlists</h2> */}
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text.</p> */}
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                // showDots={true}
                dotListClass="custom-dot-list-style"
                partialVisible={false}
                className="owl-carousel owl-theme sp-slider"
              >
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/2RsrAmlYi6OtQBM468phMH?si=fbb2dd1d766945d5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter1} alt="Image" />
                  </a>

                  {/* <h5>Web Development</h5> */}
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  {/* <h5>Brand Identity</h5> */}
                </div>
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/1sRrfJcmwcfhQCo2qTObZV?si=c364b313bc8b486f"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter3} alt="Image" />
                  </a>

                  {/* <h5>Logo Design</h5> */}
                </div>
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/5dHZM3i7mLzEjClodTxH79?si=aebc25386c1c4826 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter4} alt="Image" />
                  </a>

                  {/* <h5>Web Development</h5> */}
                </div>
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/65ZPjSHpPH1hyGGWq0qNC7?si=74effdb00ed44a69"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter5} alt="Image" />
                  </a>

                  {/* <h5>Web Development</h5> */}
                </div>
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/7IeNKjZ2xlcEcQV6MKIMRC?si=1dca598e866b4b56"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter6} alt="Image" />
                  </a>

                  {/* <h5>Web Development</h5> */}
                </div>
                <div className="item">
                  <a
                    href="https://open.spotify.com/playlist/0IA95rSnlLj4VqXahuGUFd?si=c44d392975074bce"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={meter7} alt="Image" />
                  </a>

                  {/* <h5>Web Development</h5> */}
                </div>
                <div className="item">
                  <a href="https://open.spotify.com/playlist/1sRrfJcmwcfhQCo2qTObZV?si=74e28a79a4f24a4e">
                    <img src={meter8} alt="Image" />
                  </a>
                  {/* <h5>Web Development</h5> */}
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  );
};
